module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","name":"gatsby-starter-hello-friend","short_name":"hello-friend","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84139f7f5cfe0f83dfb72545c3d80204"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"md":"(max-width: 900px)","mdMin":"(min-width: 900px)","l":"(max-width: 1250px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EF97TZRL9C","G-3HJ7RRZKTK","GTM-W9SLFDSC"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W9SLFDSC","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
